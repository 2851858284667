body {
  color: #4d4d4d;
}

.App {
  text-align: center;
}

h3 {
  font-family: fira;
}

.nav-title {
  margin: auto;
  line-height: 90px;
  font-weight: bold;
  font-size: 0.1em;
  font-family: fira;
  visibility: hidden;
  color: black;
}

.nav-title:hover {
  color: #5c5c5b;
}

a:hover {
  text-decoration: none;
  color: #5c5c5b;
}

a {
  text-decoration: none;
  color: black;
}

.card-title {
  font-family: fira;
  font-weight: bold;
}

.card-deck {
  margin-bottom: 20px;
}

.nav-title-box {
  margin: auto;
}

.animal-feed {
  padding-top: 20px;
}

.animal-main-box {
  background-color: #daf6ff;
  padding: 10px;
  margin-bottom: 30px;
}

.animal-feed-container {
  width: 90%;
  margin: auto;
}

.main-container {
  width: 90%;
  margin: auto;
}

.main-container h3 {
  text-align: center;
  color: #4d4d4d;
  font-weight: bolder;
  font-size: 3rem;
}

#basic-nav-dropdown {
  color: black;
}

.navbar-nav {
  font-size: 1.2rem;
  font-weight: 600;
}

#basic-nav-dropdown:hover {
  color: #5c5c5b;
}

.description-box {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #daf6ff;
  color: black;
  font-weight: bold;
  font-style: italic;
  margin-top: 10px;
  font-size: 1rem;
}

.description-box-animal {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #daf6ff;
  color: black;
  font-weight: bold;
  font-style: italic;
  margin-top: 10px;
  font-size: 1.2rem;
}

.calendar-description {
  background-color: #fbf59b;
}

.footer h6 {
  text-align: center;
  color: black;
  margin: auto;
  padding: 10px;
}

.footer {
  text-align: center;
  font-family: fira;
  background-color: #f8f9fa;
}

.animal-tweet {
  padding: 2px;
}

.mini-nav {
  background-color: #f8f9fa;
  font-weight: bold;
  font-family: fira;
  margin-bottom: 10px;
  font-size: 1.1em;
}

.mini-nav-donate {
  background-color: #f8f9fa;
  font-weight: bold;
  font-size: 1em;
  font-family: fira;
  margin-bottom: 10px;
}

.mini-nav-donate .nav-item {
  border-right: 1px solid #dadada;
  border-left: 1px solid #dadada;
  color: #dadada;
  background-color: black;
}

.mini-nav-donate .nav-item .nav-link {
  color: white;
}

.mini-nav-donate .nav-item .nav-link:hover {
  color: #5c5c5b;
  background-color: #5c5c5b;
}

.dropdown-menu {
  background-color: #daf6ff;
}

.nav-link {
  color: #daf6ff;
}

.support-us {
  color: white;
}

.mini-nav .nav-item {
  border-right: 1px solid #dadada;
  border-left: 1px solid #dadada;
}

.mini-nav > .nav-item:first-child {
  border-left: 2px solid #dadada;
}
.mini-nav > .nav-item:last-child {
  border-right: 2px solid #dadada;
}

.twitter-dm-button {
  margin-left: 10px;
}

.img-thumbnail {
  background-color: #daf6ff;
  border: none;
  padding: 10px;
}

.footer-content {
  width: 100%;
  padding: 10px;
  color: #daf6ff;
}

.animal-nav-image {
  background-color: white;
}

.animal-nav-image-box {
  padding: 5px;
  text-align: center;
}

.support-us {
  color: white;
}

.cow {
  background-color: #daf6ff;
}

.rabbit {
  background-color: #ffe6ea;
}

.pig {
  background-color: #d5ffcc;
}

.goat {
  background-color: #ffe4bd;
}

.hamster {
  background-color: #fcf4eb;
}

.frog {
  background-color: #bdffc3;
}

.dog {
  background-color: #f9e3ff;
}

.donkey {
  background-color: #ffe9c9;
}

.dropdown-item {
  font-weight: bold;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: white;
}

@font-face {
  font-family: fira;
  src: url(/Fira_Sans/FiraSans-Medium.ttf);
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */

  .description-box {
    margin-top: 40px;
    padding: 30px;
    font-size: 1.2rem;
  }
  .description-box-animal {
    margin-top: 40px;
    padding: 30px;
    font-size: 1.5rem;
  }

  .main-container {
    width: 60%;
    margin: auto;
  }

  .nav-title {
    visibility: visible;
    font-size: 1.5em;
    padding-left: 10px;
  }

  .animal-feed-container {
    width: 80%;
    margin: auto;
  }

  .main-image {
    content: url("/aotd.jpg");
  }

  .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .animal-feed {
    padding-top: 30px;
  }
}
